import React, { type ReactElement } from 'react'
import { Row } from 'react-bootstrap'

interface TextHeaderProps {
  header: string
  subheader?: string | ReactElement
}

export const TextHeader = (props: TextHeaderProps): ReactElement => {
  return (
    <Row style={{ marginBottom: 20 }}>
      <Row>
        <div
          style={{
            marginTop: 30,
            fontSize: 32,
            fontWeight: 'medium',
            letterSpacing: -1.5,
            lineHeight: 1.2
          }}
        >
          {props.header}
        </div>
      </Row>
      <Row>
        <div
          style={{
            marginTop: 7,
            fontSize: 16,
            fontWeight: 'regular'
          }}
        >
          {props.subheader}
        </div>
      </Row>
    </Row>)
}
