import React, { type ReactElement } from 'react'
import { Image, Row } from 'react-bootstrap'
import hero from '../assets/heropass.png'

export const Header = (): ReactElement => {
  return (
    <Row >
      <Image
        src={hero}
        fluid={true}
        style={{ padding: 0 }}
        alt="Shibuya Heaven and Hell. On the image: young people are dancing"
      />
    </Row>
  )
}
