import { initializeApp } from 'firebase/app'
import { getDatabase, connectDatabaseEmulator } from 'firebase/database'
import { getAnalytics } from 'firebase/analytics'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'

import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDKbyNUCFDYMLgRlCtoekUYubYTBbqULIQ',
  authDomain: 'ragtag-so.firebaseapp.com',
  databaseURL: 'https://ragtag-so-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'ragtag-so',
  storageBucket: 'ragtag-so.appspot.com',
  messagingSenderId: '612921186329',
  appId: '1:612921186329:web:0b8f36986b75443d7b3288',
  measurementId: 'G-M7Y915X23E'
}

const firebase = initializeApp(firebaseConfig)
const database = getDatabase(firebase)
const analytics = getAnalytics(firebase)
const functions = getFunctions(firebase)

if (location.hostname === 'localhost') {
  // Point to the RTDB emulator running on localhost.
  connectDatabaseEmulator(database, 'localhost', 9000)
  connectFunctionsEmulator(functions, 'localhost', 5001)
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true
  console.log('Connected to the local Emulator')
}

const appCheck = initializeAppCheck(firebase, {
  provider: new ReCaptchaV3Provider('6LeQ2XkkAAAAAPbNqD-V4plsXEhCgqsKo-k_3MHO'),
  isTokenAutoRefreshEnabled: true
})

export { firebase, database, analytics, appCheck, functions }
