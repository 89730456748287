/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, type ReactElement } from 'react'
import { Row, Image } from 'react-bootstrap'
import googleAddToWallet from '../assets/enUS_add_to_google_wallet_add-wallet-badge.svg'
import appleAddToWallet from '../assets/US-UK_Add_to_Apple_Wallet_RGB_101421.svg'
import { Inviter } from './inviter'
import useIsAppleDevice from '../hooks/useIsAppleDevice'
import useIsAndroidDevice from '../hooks/useIsAndroidDevice'
// import SaveToGooglePayButton from '@google-pay/save-button-react'
import { analytics } from '../Firebase'
import { logEvent } from 'firebase/analytics'

// const ADD_TO_GOOGLE_WALLET_URL = 'https://pay.google.com/gp/v/save/'

interface WalletPassesProps {
  inviter: Inviter
}

// Container for the wallet passes: apple, google, or both
export const WalletPasses = ({ inviter }: WalletPassesProps): ReactElement => {
  const [forceBothTickets, setForceBothTickets] = useState<boolean>(false)
  const [googleWalletClickCount, setGoogleWalletClickCount] = useState<number>(0)
  const isInAppBrowser = navigator.userAgent.includes('Instagram') || navigator.userAgent.includes('FBAV')

  const handleOnClickGoogle = (): void => {
    const getUrl = (): string => {
      if (googleWalletClickCount < 2 && isInAppBrowser) {
        // let them click twice
        return `intent://pay.google.com/gp/v/save/${inviter.j}#Intent;scheme=https;package=com.android.chrome;end`
      }

      return `https://pay.google.com/gp/v/save/${inviter.j}`
    }

    logEvent(analytics, 'select_item', {
      item_list_id: 'added-to-wallet-google',
      item_list_name: 'Added to Google Wallet',
      items: [{
        item_id: inviter.iid,
        item_name: inviter.fullName,
        index: googleWalletClickCount,
        item_category5: `isInstagram:${navigator.userAgent.includes('Instagram')}`
      }]
    })

    window.open(getUrl())
    setGoogleWalletClickCount((prev) => prev + 1)
  }

  const handleOnClickApple = (): void => {
    // TODO: default on Ragtag

    logEvent(analytics, 'select_item', {
      item_list_id: 'added-to-wallet-apple',
      item_list_name: 'Added to Apple Wallet',
      items: [{
        item_id: inviter.iid,
        item_name: inviter.fullName
      }]
    })
    const passUrl = `https://ragtag.tokyo/a/${inviter.iid ?? 'ragtag'}.pkpass`
    window.open(passUrl)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const saveToGoogleProps: any = {
  //   jwt: inviter.j,
  //   height: 'standard',
  //   size: 'matchparent',
  //   onSuccess: () => { console.log('clicked') },
  //   onFailure: () => { console.log('fail') }
  // }
  // const passGoogle = <SaveToGooglePayButton {...saveToGoogleProps} />

  const passGoogle = (
    <Image
      src={googleAddToWallet}
      alt="Add to Google Wallet"
      onClick={handleOnClickGoogle}
      style={{ maxHeight: '48px', maxWidth: '600px' }}
    />
  )

  const passApple = (
    <Image
      src={appleAddToWallet}
      alt="Add to Apple Wallet"
      onClick={handleOnClickApple}
      style={{ maxHeight: '48px', maxWidth: '600px', padding: 0 }}
    />
  )

  const bothTickets = (<>
    <Row style={{ paddingBottom: '9px' }}>{passApple}</Row>
    <Row>{passGoogle}</Row>
  </>)

  const switchPassButton = (text: string): ReactElement => {
    return <p
      className="d-flex justify-content-center text-secondary"
      style={{
        fontSize: '0.6em',
        textAlign: 'center',
        textDecorationStyle: 'dotted',
        textDecorationLine: 'underline',
        fontStyle: 'italic',
        paddingTop: '8px',
        paddingBottom: '0px',
        margin: 0
      }}
      onClick={() => { setForceBothTickets(true) }}>
      {text}
    </p>
  }

  if (forceBothTickets) {
    return bothTickets
  }

  if (useIsAppleDevice()) {
    return (<>
      {passApple}
      {switchPassButton('I\'m using Android')}
    </>)
  }

  if (useIsAndroidDevice()) {
    return (<>
      {passGoogle}
      {switchPassButton('I\'m using iPhone')}
    </>)
  }

  return bothTickets
}
