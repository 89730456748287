import React, { type ReactElement } from 'react'
import { Container } from 'react-bootstrap'

export const Footer = (): ReactElement => {
  return (
    <Container
      fluid
      style={{
        backgroundColor: '#343a40',
        marginTop: '48px'
      }}>
      <Container
        fluid="md"
        className="text-light"
        style={{
          paddingTop: '30px',
          paddingBottom: '30px',
          fontSize: '0.7em'
        }}>
         © 2023 Ragtag Socials<br/>
         Made with ❤️ in Tokyo.
      </Container>
    </Container>
  )
}
