import React, { type ReactElement, type ReactNode } from 'react'
import { Container, Image, Row, Col } from 'react-bootstrap'
import logo from '../assets/logo.png'
// import rsvp from '../assets/rsvp.png'

interface TicketProps {
  inviterName: string
  usk: string
  ticketBottom?: ReactNode
}

interface TicketRowProps {
  label1: string
  label2: string
  info1: string | ReactElement
  info2: string | ReactElement
}

const TicketRow = ({ label1, label2, info1, info2 }: TicketRowProps): ReactElement => {
  return (
    <div style={{ padding: '16px', paddingBottom: 0 }}>
      <Row style={{ fontSize: '0.7em' }} className='text-secondary'>
        <Col>{label1}</Col>
        <Col style={{ textAlign: 'right' }}>{label2}</Col>
      </Row>
      <Row>
        <Col>{info1}</Col>
        {info2 !== '' && <Col style={{ textAlign: 'right' }} >{info2}</Col>}
      </Row>
    </div>
  )
}

export const Ticket = (props: TicketProps): ReactElement => {
  const pricingWithStrikethrough = (
    <>
      <span style={{ fontSize: '0.75em', color: 'gray' }}>
        ¥<span style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>3000</span>
      </span>
      &nbsp;&nbsp;¥&nbsp;2500
    </>
  )

  return (
    <Container style={{
      border: '1px solid rgba(0, 0, 0, 0.175)',
      borderRadius: '13px',
      marginTop: '0px',
      marginBottom: '16px',
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      maxWidth: '460px',
      overflow: 'hidden'
    }}>
      <Row style={{
        padding: '16px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.15)'
      }}>
        <div className="d-flex justify-content-start">
          <Image
            src={logo}
            roundedCircle={true}
            style={{ width: '40px', height: '40px', boxShadow: 'rgba(0, 0, 0, 0.15) 1px 2px 10px' }}
          />
          <div style={{ fontWeight: 'medium', fontSize: '1.4em', marginLeft: '16px' }}>Shibuya Heaven and Hell</div>
        </div>
      </Row>
      <TicketRow
        label1="DATE" label2="TIME"
        info1="OCTOBER 28, 2023" info2="9:00 PM"
      />
      <TicketRow
        label1="PLACE" label2="YOU'LL PAY"
        info1="OR Tokyo, Miyashita Park" info2={pricingWithStrikethrough}
      />
      <TicketRow
        label1="INVITATION FROM" label2=""
        info1={props.inviterName} info2=""
      />
      {props.ticketBottom !== undefined && (
        <Row className="justify-content-center" style={{ padding: '16px' }}>
          {props.ticketBottom}
        </Row>
      )
      }
      {/* THE OLD BADGE WITH NAME */}
      {/* <Row
        className="mt-3"
        style={{
          backgroundColor: '#3a3a3a',
          backgroundImage: `url(${rsvp as string})`,
          backgroundSize: 'cover',
          padding: '32px',
          backgroundPosition: 'center center'
        }}>
        <Row style={{ fontSize: '1.7em', color: 'white', textTransform: 'uppercase' }}>
          {props.inviterName}
        </Row>
      </Row> */}
      {/* <div
        className="d-flex justify-content-center text-secondary"
        style={{
          fontSize: '0.7em',
          marginBottom: '30px',
          letterSpacing: 2.5,
          textAlign: 'center'
        }}>
          <p>
          DO NOT SHARE
          {isTixCounterValid(props.tixCount) &&
           <span><br/>DO NOT LET YOUR FRIENDS<br/>STEAL YOUR FREE SHOT</span>
          }
          </p>
      </div> */}
      {/* <div
        className="d-flex justify-content-center text-secondary"
        style={{
          fontFamily: 'Lucida Console, Courier New, monospace',
          fontSize: '0.7em',
          marginBottom: '30px'
        }}>
          ID:abcdef0123&nbsp;&nbsp;
          {props.inviterName !== '' && `FROM:${props.inviterName}`}
      </div> */}
    </Container >

  )
}
