/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type ReactElement } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Header } from '../common/Header'
import { TextHeader } from '../common/TextHeader'
import { Ticket } from '../common/Ticket'
import { getLocalInviter } from '../common/inviter'
import { WalletPasses } from '../common/WalletPasses'

// V2 of the invitaton page
const InvitationOneStep = (): ReactElement => {
  const { invitationKey } = useParams()

  const inviter = getLocalInviter(invitationKey as string)

  if (inviter === undefined) {
    return (
      <Container fluid="md">
        <Header />
        <Container style={{ height: '100%' }}>
          <TextHeader
            header="Invitation not found"
            subheader={
              <div>If you think it is a mistake, contact us:
                <a href="https://www.instagram.com/ragtagsocials/" target="_blank" rel="noreferrer">@ragtagsocials</a>
              </div>}
          />
        </Container>
      </Container>
    )
  }

  return (
    <Container fluid="md">
      <Header />
      <Container style={{ height: '100%' }}>
        <TextHeader
          header={`${inviter.fullName} Invites You to Shibuya Heaven and Hell`}
          subheader={
            <>
              Add the invitation to your Wallet and enjoy Ragtag at discounted prices.
              You will pay when you arrive at the event.
            </>
          }
        />
        <Row style={{ padding: '10px' }}>
          <Ticket
            inviterName={inviter.fullName}
            usk=""
            ticketBottom={
              <WalletPasses inviter={inviter} />
            }
          />
        </Row>
      </Container>
    </Container>
  )
}

export default InvitationOneStep
