export interface Inviter {
  fullName: string // to display on the page
  shortName: string // for Urls
  iid?: string //  # For hero URI and JWT ID
  j?: string //  # For JWT ID
  h?: string // hash for the tickets
}
export function localInviterExist (invitationKey: string): boolean {
  return inviterList.filter((el) => el.shortName === invitationKey).length > 0
}

export function getLocalInviter (invitationKey: string): Inviter | undefined {
  return inviterList.find((el) => el.shortName === invitationKey)
}

const inviterList: Inviter[] = [
  {
    fullName: 'Tester',
    shortName: 'tester',
    iid: 'test',
    j: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJyYWd0YWctd2FsbGV0LXdlYi1jbGllbnRAcmFndGFnLXNvLmlhbS5nc2VydmljZWFjY291bnQuY29tIiwiYXVkIjoiZ29vZ2xlIiwib3JpZ2lucyI6W10sInR5cCI6InNhdmV0b3dhbGxldCIsInBheWxvYWQiOnsiZXZlbnRUaWNrZXRPYmplY3RzIjpbeyJpZCI6IjMzODgwMDAwMDAwMjIyMjE5MTgudGVzdC0wOWYiLCJjbGFzc0lkIjoiMzM4ODAwMDAwMDAyMjIyMTkxOC5hcmFndGFnIiwic3RhdGUiOiJBQ1RJVkUiLCJ0ZXh0TW9kdWxlc0RhdGEiOlt7ImlkIjoiaW52IiwiaGVhZGVyIjoiWW91IHdlcmUgaW52aXRlZCBieToiLCJib2R5IjoiVGVzdGVyIn0seyJpZCI6InByYyIsImhlYWRlciI6IllvdSdsbCBQYXkiLCJib2R5IjoiwqUgMjUwMCJ9XSwiaGVyb0ltYWdlIjp7InNvdXJjZVVyaSI6eyJ1cmkiOiJodHRwczovL3JhZ3RhZy50b2t5by9nL3Rlc3QucG5nIn19fV19LCJpYXQiOjE2OTc3MjE5NDh9.Q1Rdel8UT5GoWUUbHn1Ni4T6IG5119KMspdJLP8voJM9Ef3iHflQWRWTyJ_92O1ehhoOF9FmvTssqLoGU-0RO6L4yQ0rgZffM2lqPudZbnuQdBgprviXXw-sva-HNDAuDWBKPGzBapPh_OvEUgUtv7thkxLkdfqydtzUgLRs5iCiQd0eHTeIJ-f9Yh5NJAoihysN7wZc10rW_Ow78_RrpzNKtcKt67LeeGQp4jR9zJt6vMtUdJtrHW-4-Ew-QpffjqTVUf-OuP5oRXGpWD57SjGyEo82rQXtUlEESA9CBeYPHGAbfHDK1F-G9pHAqBUg7WbaPhOjebLMwGfJnh5N8A',
    h: '09f'
  },
  {
    fullName: 'Przemek',
    shortName: 'przemek',
    iid: 'prze',
    j: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJyYWd0YWctd2FsbGV0LXdlYi1jbGllbnRAcmFndGFnLXNvLmlhbS5nc2VydmljZWFjY291bnQuY29tIiwiYXVkIjoiZ29vZ2xlIiwib3JpZ2lucyI6W10sInR5cCI6InNhdmV0b3dhbGxldCIsInBheWxvYWQiOnsiZXZlbnRUaWNrZXRPYmplY3RzIjpbeyJpZCI6IjMzODgwMDAwMDAwMjIyMjE5MTgucHJ6ZS0wNDgiLCJjbGFzc0lkIjoiMzM4ODAwMDAwMDAyMjIyMTkxOC5hcmFndGFnIiwic3RhdGUiOiJBQ1RJVkUiLCJ0ZXh0TW9kdWxlc0RhdGEiOlt7ImlkIjoiaW52IiwiaGVhZGVyIjoiWW91IHdlcmUgaW52aXRlZCBieToiLCJib2R5IjoiUHJ6ZW1layJ9LHsiaWQiOiJwcmMiLCJoZWFkZXIiOiJZb3UnbGwgUGF5IiwiYm9keSI6IsKlIDI1MDAifV0sImhlcm9JbWFnZSI6eyJzb3VyY2VVcmkiOnsidXJpIjoiaHR0cHM6Ly9yYWd0YWcudG9reW8vZy9wcnplLnBuZyJ9fX1dfSwiaWF0IjoxNjk3NzIxOTQ5fQ.OxXyXWVl-ohZWjIkSla4f0u8K9rVJlurW1MLeK8Yp1AIlkOCQq375PNpxdrEVNIv4uutnIx8acnZD_aYSj6pXPln0p2wEtrFjCf3fBGCQ-b7xCyaiRrCb4ns6SzybhawZiHNroFtySttC4MRD-i7tJzVH65V4PA5ZsDVk2sTNm1dMpGY6BxhHxG0mBOqyS9UzqoNWQvcdHuhxF4Uq-S0XlXgX_fpSZUx7LqylbG8QBie6HP9giK7Y47Pd6Dj7IP4bJqT6e7qwiS4IwMs1-MkMNBYeKhKvd_tELu5RqRkhIXbQ4Cyfz2e9Nph2lxBzloXIIziORsAdAEOz4thoWoogg',
    h: '048'
  },
  {
    fullName: 'Ragtag',
    shortName: 'ragtag',
    iid: 'ragt',
    j: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJyYWd0YWctd2FsbGV0LXdlYi1jbGllbnRAcmFndGFnLXNvLmlhbS5nc2VydmljZWFjY291bnQuY29tIiwiYXVkIjoiZ29vZ2xlIiwib3JpZ2lucyI6W10sInR5cCI6InNhdmV0b3dhbGxldCIsInBheWxvYWQiOnsiZXZlbnRUaWNrZXRPYmplY3RzIjpbeyJpZCI6IjMzODgwMDAwMDAwMjIyMjE5MTgucmFndC0yOGYiLCJjbGFzc0lkIjoiMzM4ODAwMDAwMDAyMjIyMTkxOC5hcmFndGFnIiwic3RhdGUiOiJBQ1RJVkUiLCJ0ZXh0TW9kdWxlc0RhdGEiOlt7ImlkIjoiaW52IiwiaGVhZGVyIjoiWW91IHdlcmUgaW52aXRlZCBieToiLCJib2R5IjoiUmFndGFnIn0seyJpZCI6InByYyIsImhlYWRlciI6IllvdSdsbCBQYXkiLCJib2R5IjoiwqUgMjUwMCJ9XSwiaGVyb0ltYWdlIjp7InNvdXJjZVVyaSI6eyJ1cmkiOiJodHRwczovL3JhZ3RhZy50b2t5by9nL3JhZ3QucG5nIn19fV19LCJpYXQiOjE2OTc3MjE5NDl9.gyq-zSbRqr31vVLV0APxX9FaVCd6gka1yOr1chs6dtgWOG_11LLmEWPTrdCY729uCv6wYmjR9NB3MqwkzQf9voCzkurg2xdPNgC7hXAUsesGCKvwDcmG8ndXm-QwnRdIHIU2aL_Puxm7XeY8i1cny38VWpnnrQI2y2GcDsiR6CoXRfqOuqipg-G_M2CXp6NPhkwD3jdJJLVi648TdasP35_RHzFx-qIF3Z6d-5u36ECUzsv1gSJlscTzkGKYSxqAwpW1vBnhiMrcfKwyFS6-ch4S1kdgHN1yUZ6CbfWoH7doNDCEsypOyGSalU9f-flvM7G85VW6SDRVi1wt3cAB2A',
    h: '28f'
  },
  {
    fullName: 'Sean',
    shortName: 'sean',
    iid: 'sean',
    j: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJyYWd0YWctd2FsbGV0LXdlYi1jbGllbnRAcmFndGFnLXNvLmlhbS5nc2VydmljZWFjY291bnQuY29tIiwiYXVkIjoiZ29vZ2xlIiwib3JpZ2lucyI6W10sInR5cCI6InNhdmV0b3dhbGxldCIsInBheWxvYWQiOnsiZXZlbnRUaWNrZXRPYmplY3RzIjpbeyJpZCI6IjMzODgwMDAwMDAwMjIyMjE5MTguc2Vhbi0zNmMiLCJjbGFzc0lkIjoiMzM4ODAwMDAwMDAyMjIyMTkxOC5hcmFndGFnIiwic3RhdGUiOiJBQ1RJVkUiLCJ0ZXh0TW9kdWxlc0RhdGEiOlt7ImlkIjoiaW52IiwiaGVhZGVyIjoiWW91IHdlcmUgaW52aXRlZCBieToiLCJib2R5IjoiU2VhbiJ9LHsiaWQiOiJwcmMiLCJoZWFkZXIiOiJZb3UnbGwgUGF5IiwiYm9keSI6IsKlIDI1MDAifV0sImhlcm9JbWFnZSI6eyJzb3VyY2VVcmkiOnsidXJpIjoiaHR0cHM6Ly9yYWd0YWcudG9reW8vZy9zZWFuLnBuZyJ9fX1dfSwiaWF0IjoxNjk3NzIxOTQ5fQ.c8-pHd1YfFgoBsIGxnvewkp990tytXOJYOCMyZD67GVh_7L780y7xu9JbBxaRnz4xL6Qh5iz_ehQntf-uheh0UHlOaQJjY_l3_JgqgUkhgdMeyugihir20tXCENsTvhPQ5lyagngkVx6RfRcD8ukJuRbQXXpnw3Y5Oczqzc4mJo3Xz6PXFIOVOVmrBqNpDy1KMCi0zom3M2sJv-NwKIjcLLfky1BZ3ksqV2znN7zjjbMlE95ClXDTsDHaifPe6_FfqT7T_i5_hPD7cI5x9pMkvnqXKt9RpStDpYTHFrMayxuubdtOgaw5Kh4meEoHNQ7i25H5C3QbGkuJmxx07313A',
    h: '36c'
  }
]
